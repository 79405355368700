.modal {
  min-width: 60%;
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  /* Set width to 80% of the screen */
  background-color: white;
  border-radius: 5px;
  padding: 20px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close-btn button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  width: 25%;
}

.modal-close-btn button:hover {
  background-color: #f44336;
  color: white;
}

.product-list-item>img {
  margin-left: 2%;
  margin-right: 2%;
  height: 60px;
}