@media (max-width: 420px) {
    .main-container {
        height: 83.5vh;
    }

    .landing-container>h1 {
        margin-bottom: 10%;
    }

    .form-cont {
        margin-bottom: 30%;
        height: 7.5%;
    }

    .scan-form {
        width: 15%;
    }

    .search-form>.search-btn {
        width: 25%;
    }

    .search-btn>.box>svg {
        height: 28px;
        width: 28px;
    }

    .search-bar {
        display: none;
    }

    /* cart */

    .main-container>div {
        height: 100%;
        width: 100%;
    }

    .main-container>div>.cartpage {
        margin-top: 0;
        height: 83.5vh;
        width: 100%;
        border-radius: 0;
        overflow: hidden;
    }

    .cartpage>.modal-title {
        height: 10%;
    }

    .cartitem>.grid-item {
        height: fit-content;
    }

    .modal-title>#close-div {
        margin-left: 85%;
        width: 15%;
    }

    .modal-title>#close-div>button {
        border-top-right-radius: 0;
    }

    .cartpage>.cartitem {
        display: grid;
        grid-template-columns: 1fr;
        position: absolute;
        top: 30%;
        height: 65%;
    }

    .cartitem>.grid-item>.cart-product {
        width: 80%;
    }

    #cart-title>h2 {
        margin-bottom: 5%;
        height: 70%;
    }

    #cart-title>h2>.basket {
        height: 50px;
        width: 50px;
    }

    #cart-title>.title-btns {
        width: 100%;
        height: 10%;
    }

    #cart-title {
        height: 17vh;
    }

    /*cart-item*/

    .cart-product>.btn-remove-div>.btn-remove {
        height: 30px;
    }

    .btn-remove>svg {
        height: 18px;
        width: 18px;
    }

    .grid-item>.cart-product>img {
        height: 250px;
    }

    .product-info>h3 {
        font-size: 1.5rem;
    }

    .product-info>.rating-details>p {
        font-size: 1.2rem;
        width: 15%;
    }

    .product-info>.rating-details>p>.star {
        width: 16px;
        height: 16px;
    }

    .rating-details>.btn-details {
        font-size: 1.2rem;
    }

    /*profile*/

    .page>.contact {
        position: relative;
        grid-template-columns: 1fr;
        /* grid-template-rows: 50vh; */
        height: fit-content;
        background-color: #f5f5f5;
    }

    .product-details>.grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 80%;
        /* gap: 20px; */
    }

    .grid-container>.prim {
        border-bottom-left-radius: 0px;
    }

    .doi {
        border-top-right-radius: 5px;
    }

    .trei {
        border-bottom-left-radius: 5px;
    }

    .grid-container>.ultim {
        border-top-right-radius: 0px;
    }

    .header {
        font-weight: bold;
        margin-bottom: 5px;
    }

    .page>.product-details {
        width: 100%;
    }

    .page>.profile-table {
        display: flex;
    }

    .page>.veg {
        top: 0%;
    }

    .page>.savebtn {
        top: 0%;
    }

    #vegan>div {
        display: inline-flex;
    }

    .page>.savebtn>#save {
        width: auto;
    }

    #allergens>.alergen {
        font-weight: bold;
        font-size: xx-large;
        margin-left: 25%;
    }

    .form-group>#allergens>div {
        margin-left: 25%;
        display: inline-flex;
    }

    #allergens {
        width: 70%;
        margin-left: 7%;
    }

    #allergens>div>input {
        width: 20px;
        height: 20px;
    }

    .umplutura {
        height: 10%;
        width: 100%;
        background-color: #f5f5f5;
    }

    /*search-products*/

    body>.modal {
        min-width: 100.1%;
        height: 83.6vh;
        position: absolute;
        left: 50%;
        top: 58.3%;
        transform: translate(-50%, -50%);
        width: fit-content;
        /* height: fit-content; */
        background-color: white;
        border-radius: 5px;
        padding: 0;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .modal-content>.modal-close-btn>button {
        font-size: larger;
        width: 47.5%;
    }

    #scanned-prods {
        font-size: 2rem;
        text-align: center;
    }

    .textQr {
        font-size: 1.25rem;
    }

    .product-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product-list>.product-list-item {
        width: 95%;
    }

    .product-list-item>button {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-list-item>button>span {
        display: none;
    }

    .product-list-item>button>svg {
        display: block;
    }

    .scanned-product>.scanned-btn {
        height: 4vh;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        width: 75%;
    }

    .scanned-btn>.btn-details {
        width: 40%;
        height: 100%;
        margin: 0;
    }

    .scanned-btn>select {
        width: 10%;
        height: 90%;
    }

    /*product details*/

    .container>.product-details-container {
        width: 100%;
        left: 50%;
        /* top: 50.5%; */
        transform: translate(-50%, -50%);
        background-color: #e2e2e2;
        border-radius: 0;
        height: 100%;
    }

    .product-details-container>div>button {
        border-radius: 5px;
        width: 30%;
        height: 100%;
    }

    .flex-details {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .flex-details>.product-details {
        width: 100%;
    }

    .product-details-container>.btn-back {
        justify-content: center;
    }

    .product-details-container>.btn-back>button {
        width: 20%;
    }

    .umplutura1 {
        background-color: #e2e2e2;
        height: 20px;
    }

    .dropdown {
        max-width: 13em;
        /* margin: 80px auto 0; */
        position: relative;
        width: 100%;
        align-items: flex-end;
    }

    .dropdown-btn {
        background: #416d19;
        font-size: 18px;
        width: 80%;
        border: none;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.7em 0.5em;
        border-radius: 0.5em;
        cursor: pointer;
    }

    .arrow {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #fff;
        transition: transform ease-in-out 0.3s;
    }

    .dropdown-content {
        /* z-index: 100; */
        list-style: none;
        position: relative;
        /* top: 3.2em; */
        width: 80%;
        visibility: hidden;
        overflow: hidden;
        padding: 0;
    }

    .dropdown:focus-within .dropdown-content {
        visibility: visible;
    }

    .dropdown:focus-within .dropdown-btn>.arrow {
        transform: rotate(180deg);
    }

    .nav-btn {
        background-color: #416d19;
    }

    .dropdown-content li {
        margin-top: 2%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #416d19;
        border-radius: 0.5em;
        position: relative;
        left: 100%;
        transition: 0.5s;
        /* transition: opacity 0.5s ease; */
    }

    .dropdown:focus-within .dropdown-content li {
        left: 0;
    }

    .dropdown-content li:hover {
        background: #1d1f24;
    }

    .dropdown-content li a {
        display: block;
        padding: 0.7em 0.5em;
        color: #fff;
        margin: 0.1em 0;
        text-decoration: none;
    }

    .nutriments-cart {
        position: relative;
        top: 10%;
    }
}