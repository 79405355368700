/* 

#FFF67E
#BFEA7C
#9BCF53
#416D19
rgb(255, 246, 126)
rgb(191, 234, 124)
rgb(155, 207, 83)
rgb(65, 109, 25)

*/

.authContainer {
  width: 35vw;
  margin: 0 auto;
  /* border: 1px solid black; */
  height: 65vh;
  background-color: #f5f5f5;
  border-radius: 10px;
}

h3 {
  text-align: center;
  margin: 0;
  font-size: 1.75rem;
  font-weight: bold;
  color: black;
}

.btn-container {
  width: 100%;
  display: inline-flex;
  position: relative;
  height: 15%;
  border-radius: 10px 10px 0 0;
}

.btn {
  width: 100%;
  padding: 20px;
  background-color: #9bcf53;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  font-size: large;
  font-weight: bold;
}

.btn-active {
  background-color: #416d19;
}

.btn-left {
  border-radius: 10px 0 0 0;
}

.btn-right {
  border-radius: 0 10px 0 0;
}

.btn:hover {
  background-color: #416d19;
}

.form-container {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.auth-form {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.form-gr {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth-form input {
  height: 65%;
  padding: 10px;
  margin: 5%;
  width: 70%;
  border: 1px solid black;
  border-radius: 3px;
}

.btn-submit {
  width: 10rem;
  margin: 30px;
  margin-top: 6%;
  padding: 10px;
  background-color: #9bcf53;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  font-size: large;
  font-weight: bold;
  position: relative;
  border-radius: 3px;
}

.login {
  margin-top: 5%;
}

.btn-submit:hover {
  background-color: #416d19;
}

.form-group-log {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 760px) {
  .authContainer {
    width: 90vw;
    height: 70vh;
  }

  .btn-container {
    height: 10%;
  }

  .btn {
    padding: 15px;
    font-size: medium;
  }

  .form-container {
    height: 80%;
  }

  .auth-form input {
    padding: 8px;
    margin: 8px;
  }

  .btn-submit {
    width: 8rem;
    margin: 20px;
    padding: 10px;
    font-size: medium;
  }

  .login {
    margin-top: 20px;
  }
}

@media (min-width: 761px) and (max-width: 1024px) {
  .authContainer {
    width: 80vw;
    height: 60vh;
  }

  .btn-container {
    height: 8%;
  }

  .btn {
    padding: 12px;
    font-size: small;
  }

  .form-container {
    height: 75%;
  }

  .auth-form input {
    padding: 6px;
    margin: 6px;
  }

  .btn-submit {
    width: 7rem;
    margin: 15px;
    padding: 8px;
    font-size: small;
  }

  .login {
    margin-top: 20px;
  }
}