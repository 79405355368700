.cart-product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0;

  border: 1px solid #000;
  border-radius: 5px;
  background-color: #fff;
  width: 65%;
  height: fit-content;
  z-index: 2;
}

.cart-product img {
  position: relative;
  width: 100%;
  height: 20vh;
  object-fit: scale-down;
}

.product-info {
  width: 100%;
  align-items: center;
}

.product-info>h3 {
  height: 80px;
}

.rating-details {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 10px 0;
  width: 100%;
}

.btn-details {
  width: 25%;
  background-color: #9bcf53;
  border: none;
  color: white;
  cursor: pointer;
  padding: 2%;
  border-radius: 3px;
  transition: 0.2s;
}

.btn-remove-div {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
}

.btn-remove {
  width: 15%;
  height: 100%;
  background-color: #e60000;
  border: none;
  color: white;
  cursor: pointer;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
}

.btn-remove:hover {
  background-color: #c20000;
}

.btn-details:hover {
  background-color: #416d19;
}

.star {
  color: #9bcf53;
  margin: 0;
}

.accept-btn {
  background-color: #60c700;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  padding: 1%;
}

.reject-btn {
  background-color: #e60000;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  padding: 1%;
}

.rating-details>p>button {
  border: none;
  cursor: pointer;
  background-color: white;
}