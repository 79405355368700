.main-dashboard {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  background-color: #e2e2e2;
}

.main-dash-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  background-color: #f2f2f2;
}

.btn-dash {
  background-color: #a7a7a7;
  color: white;
  padding: 14px 20px;
  border: none;
  font-size: 1.2rem;
  border-bottom: 1px solid #e2e2e2;
}

.btn-dash:hover {
  background-color: #2d2d2d;
  color: white;
  padding: 14px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: 0.1s;
}

.main-dashboard a {
  text-decoration: none;
  color: white;
  text-align: center;
}

/* form styles */

.form-group-large {
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.form-group-d {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.form-group-d input {
  width: 80%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;
}

.btn-dash-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#prod-image {
  padding: 10px 20px;
}

.main-dash-content h3 {
  text-align: center;
  margin-top: 2%;
  font-weight: normal;
}

/* submissions styles */

.dash-submissions {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.dash-cartitem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  grid-auto-flow: row;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.grid-item-dash {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px;
  margin-left: 0;
  margin-right: 0;
}
