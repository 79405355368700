.textQr {
  text-align: center;
}

#qrcam {
  margin-top: 5%;
  margin-bottom: 5%;
  height: 40vh;
}

.product-list {
  padding: 0;
}

.product-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
  height: 10%;
  background-color: white;
  margin: 0;
  padding: 10px 0;
}

.product-list-item p {
  margin: 0 2%;
  font-size: 1.2rem;
  font-weight: 600;
  width: 40%;
}

.product-list-item button {
  margin-left: auto;
  margin-right: 2%;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;

  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  width: 10%;

}

.bi-three-dots-vertical {
  display: none;
}

.product-list-item:hover {
  background-color: #e0e0e0;
}

#close-qr {
  position: relative;
  background-color: red;
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.scanned-product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scanned-product>.scanned-btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.scanned-product>img {
  width: auto;
  height: 90%;
}

.product-list .btn-cart {
  margin-left: 2%;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-cart>svg {
  padding: 0;
}