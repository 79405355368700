.landing-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.landing-container h1 {
  color: white;
  font-size: 4rem;
  margin: 1% 0;
}

.landing-container h2 {
  color: white;
  font-size: 2rem;
}

.form-cont {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 7%;
  margin-top: 3%;
}

.landing-container input {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0 2%;
  border: none;
  font-size: 1.5rem;
}

.landing-container input:focus {
  outline: none;
}

.form-cont button {
  height: 100%;
  border: none;
  background-color: #9bcf53;
  color: #000;
  cursor: pointer;
  width: auto;
}

.qr {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 2%;
  padding-right: 2%;
}

.search-form {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 27vw;
}

.search-btn {
  height: 100%;
  background-color: #9bcf53;
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0 5%;
}

.qr-btn {
  height: 100%;
  font-weight: bold;
  border: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.landing-container button:hover {
  background-color: #416d19;
  color: white;
  transition: 0.3s;
}

.scan-form {
  height: 100%;
  /* width: 5%; */
}

.lupa {
  padding-left: 5px;
}

.box-qr {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .landing-container h1 {
    font-size: 3rem;
  }

  .landing-container h2 {
    font-size: 1.5rem;
    text-align: center;
  }

  .form-cont {
    height: 10%;
    margin-top: 5%;
  }

  .search-form input {
    width: 80%;
    font-size: 1.2rem;
  }

  .search-form {
    width: 40vw;
  }

  .search-btn {
    font-size: 1rem;
    padding: 0 3%;
    width: 20%;
  }

  .lupa {
    padding: 0;
  }

  .scan-form {
    height: 100%;
    /* width: 5%; */
  }
}

@media (max-width: 480px) {
  .landing-container h1 {
    font-size: 2rem;
  }

  .landing-container h2 {
    font-size: 1rem;
  }

  .form-cont {
    /* height: 15%; */
    margin-top: 10%;
  }

  input {
    width: 100%;
    font-size: 1rem;
  }

  .search-form {
    width: 60vw;
  }

  .search-btn {
    font-size: 0.8rem;
    padding: 0 2%;
  }
}