nav {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 10vh;
  position: relative;
  background-color: #9bcf53;
}

.navbar-brand {
  text-decoration: none;
  color: white;
  font-size: 50px;
  font-weight: bold;
  margin: 3%;
  padding: 3%;
}

.nav-links {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
  margin: 0 5%;
  font-size: large;
}

.nav-btn {
  padding: 10px 15px 10px 15px;
  margin: 2%;
  background-color: #416d19;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  font-size: large;
  font-weight: bold;
}

.nav-btn:hover {
  background-color: #244700;
}

.nav-links a:hover {
  color: #416d19;
  transition: 0.1s;
}

.nolog {
  background-color: #9bcf53;
}

.nolog:hover {
  background-color: #9bcf53;
  color: #416d19;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
}

#arrow {
  padding-left: 5px;
}

.prof {
  padding-left: 5px;
}

.basket {
  padding-left: 5px;
  padding-bottom: 2px;
}

@media (min-width: 420px) {
  .dropdown-btn {
    display: none;
  }
}

@media (max-width: 420px) {
  nav {
    z-index: 11;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    background-color: #9bcf53;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100vw;
    height: 16.5vh;
    position: relative;
  }

  .navbar-brand {
    text-decoration: none;
    color: white;
    font-size: 30px;
    font-weight: bold;
    margin: 3%;
    padding: 3%;
  }

  .nav-links {
    position: fixed;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    top: 7vh;
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}