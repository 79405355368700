.modal-title {
  width: 100%;
}

.modal-title > h2 {
  margin-top: 0;
}

.cartpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  background-color: #f5f5f5;
  width: 80vw;
  height: 80vh;
  border-radius: 3px;
}

.cartitem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  grid-auto-flow: row;
  margin: 1% 0 0 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px;
  margin-left: 0;
  margin-right: 0;
}

#close-div {
  width: 5%;
  margin: 0;
  margin-left: 95%;
  height: 35%;
}

#close-div > button {
  margin: 0;
  padding: 0;
}

#close {
  position: relative;
  background-color: red;
  padding: 10px 15px 10px 15px;
  border: none;
  border-top-right-radius: 5%;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  font-size: large;
  font-weight: bold;
}

#close:hover {
  background-color: rgb(164, 3, 3);
}

#cart-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#cart-title > h2 > svg {
  margin: 0;
  padding: 0;
}

.title-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 0 0 2% 0;
  padding: 0;
  border-radius: 5px;
}

.title-btns > button {
  position: relative;
  border-radius: 5px;
  margin-left: 2%;
  margin-right: 2%;
  font-weight: bolder;
  font-size: 1rem;
  padding: 10px 0 10px 0;
}
