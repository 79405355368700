.product-details-container {
  display: grid;
  grid-template-columns: 1fr;
  overflow-y: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 111%;
  height: 88.5vh;
  background-color: #e2e2e2;
  border-radius: 5px;
  z-index: 2;
}

.btn-back {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #e2e2e2;
}

.product-details-container>.btn-back>button {
  position: relative;
  background-color: red;
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  font-size: larger;
  font-weight: bold;
  width: 15%;
  height: 100%;
}

.flex-details {
  position: relative;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  width: 100%;
  height: fit-content;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.flex-details h2 {
  font-size: 2rem;
  margin-bottom: 0;
  text-align: center;
}

.flex-details .left-col {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.product-details {
  width: 50%;
  display: block;
  justify-content: center;
  align-items: center;
}

.product-details table {
  width: 100%;
  border-collapse: collapse;
  background-color: #e2e2e2;
}

.product-details table thead {
  background-color: #9bcf53;
  border-bottom: 1px solid #000000;
  padding: 10%;
}

.product-details table th {
  padding: 10px;
}

.product-details table td {
  padding: 10px 40px;
  text-align: center;
}

.nutriscore-likes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #9bcf53;
  padding: 0 20px;
}

.nutriscore-likes #score {
  padding: 0 10px;
  font-size: 1.3rem;
  font-weight: bold;
  background-color: #f7a800;
  border-radius: 5px;
  margin-left: 5px;
}

.like {
  margin-left: 5px;
}

.ingredients {
  width: 100%;
  padding: 2% 0;
  text-align: center;
  background-color: #e2e2e2;
}

.allergens {
  width: 100%;
  text-align: center;
  background-color: #e2e2e2;
}

.cartpage>.details-product-cart {
  position: absolute;
  width: 73vw;
  height: 80vh;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}