body {
  background-color: #f5f5f5;
}

.screen-body {
  height: 100vh;
  width: 100vw;
}

.main-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 90vh;
  background-image: url("../../public/login-pic.jpg");
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply;
}
