::-webkit-scrollbar {
  display: none;
}

.page {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  position: absolute;
  top: 0.01vh;
}

.contact {
  width: 100vw;
  /* height: 65vh; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 80vh;
  grid-auto-flow: row;
  gap: 30px;
  margin-bottom: 5%;
}

.contact-mes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#title {
  width: 100vw;
  font-size: 3rem;
  font-weight: bold;
  color: black;
  padding-right: 0;
  padding-left: 0;
  margin: 0;
  text-align: center;
  background-color: #f5f5f5;
}

h1 {
  font-size: 3rem;
  font-weight: bold;
  color: black;
  padding: 10px;
  margin: 0;
  text-align: center;
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.form-group form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.inp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.name {
  width: 100%;
  border-radius: 5px;
  font-size: 2rem;
  font-weight: bold;
  color: black;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.name input {
  width: 50%;
  height: 10%;
  border-radius: 5px;
  border: 1px solid #000;
  margin: 10px;
  margin-left: 25%;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1.5rem;
}

#save {
  padding: 10px;
  background-color: #9bcf53;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  font-size: large;
  font-weight: bold;
  position: relative;
  border-radius: 3px;
}

.savebtn {
  width: 100%;
  height: 10vh;
  /* position: relative; */
  top: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.savebtn button {
  width: 15%;
}

.inp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.labname {
  margin-left: 25%;
}

.form-group fieldset {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  margin-left: 15%;
  padding: 0;
  border: 0;
}

.form-group legend {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: black;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

#allergens label {
  font-size: 1.25rem;
}

#allergens div {
  width: 100%;
  margin-left: 16%;
}

#allergens>.alergen {
  font-weight: bold;
  font-size: xx-large;
}

.veg {
  /* position: relative; */
  top: 20vh;
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#vegan {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0;
  border: none;
}

#vegan legend {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: black;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

#vegan label {
  font-size: 1.25rem;
}

.areuvegan>p {
  font-size: xx-large;
  font-weight: bolder;
}

.veg .product-details table {
  margin-top: 10vh;
}

#table-header {
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
  background-color: #cfcfcf;
}

.veg .product-details table p {
  font-size: 1.2rem;
}

.grid-container {
  display: grid;
  width: 50vw;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, auto);
  /* border: 1px solid #000000; */
  /* border-radius: 5px; */
  /* gap: 20px; */
}

.grid-container>.grid-item {
  border: 1px solid #000000;
  /* width: 100%; */
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
  background-color: #d0cece;
  margin: 0;
}

.header {
  font-weight: bold;
  margin-bottom: 5px;
}

.gender-label {
  font-weight: bold;
  font-size: xx-large;
}

.prim {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ultim {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}